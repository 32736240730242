import axios from 'axios'
import qs from 'qs'

// 创建axios实例
const service = axios.create({
  baseURL: 'https://fc.itwmw.com/',
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.transformRequest = [function(data) {
      if (data instanceof FormData) {
        return data
      } else {
        return qs.stringify(data)
      }
    }]
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  async(response) => {
    const res = response.data
    if (Number.parseInt(res.code) !== 200 && Number.parseInt(res.code) !== 0) {
        return Promise.reject(res)
    } else {
      return res
    }
  }, error => {
    return Promise.reject(error)
  }
)

export default service
