<template>
  <div id="app">
    <el-row>
      <el-button type="text" :style="{'margin-bottom': '15px','color':color}" @click="showLove">{{text}}</el-button>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input placeholder="请输入分享内容" v-model="form.url">
            <i v-if="form.url" slot="suffix" class="el-input__icon el-icon-close" @click="clear"></i>
            <template slot="prepend">分享内容</template>
            <el-button slot="append" type="primary" @click="get()">获取</el-button>
          </el-input>
        </el-form-item>

        <el-form-item v-if="showVideoDom">
          <el-input v-model="video" readonly>
            <template slot="prepend">视频地址</template>
            <el-button slot="append" type="primary" @click="open(video)">打开</el-button>
          </el-input>
        </el-form-item>
        <el-form-item v-if="showMusicDom" readonly>
          <el-input v-model="music">
            <template slot="prepend">音乐地址</template>
            <el-button slot="append" type="primary" @click="open(music)">打开</el-button>
          </el-input>
        </el-form-item>
        <el-form-item v-if="showImageDom">
          <el-carousel indicator-position="outside" ref="imageShow" :height="imageShowDomHeight + 'px'">
            <el-carousel-item v-for="(image,index) in images" :key="index">
              <img :src="image.blob" style="width: 100%;height: 100%;object-fit: contain"  alt=""/>
            </el-carousel-item>
          </el-carousel>
        </el-form-item>

<!--        <el-form-item v-if="images.length === 0" style="float:right;">-->
<!--          <el-button-group>-->
<!--          <el-button type="primary" @click="copyUrl()">复制</el-button>-->
<!--          <el-button type="primary" @click="open()">打开</el-button>-->
<!--          <el-button type="primary" @click="downloadMedia()">下载</el-button>-->
<!--          </el-button-group>-->
<!--        </el-form-item>-->
        <el-alert
            v-if="images.length === 0"
            title="温馨提示：如果点击打开不能正常播放或者下载，请手动复制媒体地址到浏览器中打开。"
            type="success"
            :center="true"
            :closable="false">
        </el-alert>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import request from "./request";
import download from 'downloadjs'
import copy from 'copy-text-to-clipboard';
import md5 from 'crypto-js/md5'
import CryptoJS from 'crypto-js'

const TYPE_VIDEO = 1
const TYPE_IMAGE = 2
const TYPE_MUSIC = 4

export default {
  name: 'App',
  components: {
  },
  data(){
    return {
      form: {
        url :''
      },
      type: 0,
      images: [],
      video: '',
      music: '',
      text:"千万不要点这里",
      color: '#409EFF',
      init: false,
      loading: null,
      loadingText: '',
      loadingType: 0,
      imageShowDomHeight: 1000,
      showImageDom: false,
      showVideoDom: false,
      showMusicDom: false
    }
  },
  watch: {
    images: function (images) {
      if (images.length > 0){
        this.$nextTick(() => {
          let imageShowDomTop = this.$refs.imageShow.$el.getBoundingClientRect().top
          let clientHeight = document.documentElement.clientHeight
          this.imageShowDomHeight = clientHeight - imageShowDomTop - 80
        })
      }

      return images
    },
    type: function (type) {
      this.showImageDom = false
      this.showVideoDom = false
      this.showMusicDom = false
      if ((type & TYPE_VIDEO) === TYPE_VIDEO){
        this.showVideoDom = true
      }

      if ((type & TYPE_IMAGE) === TYPE_IMAGE){
        this.showImageDom = true
      }

      if ((type & TYPE_MUSIC) === TYPE_MUSIC){
        this.showMusicDom = true
      }
    }
  },
  mounted(){
    request({
      url:"/media",
      method: "post",
      data:{
        'url' :this.form.url
      }
    }).catch(()=>{}).finally(()=>{
      this.init = true
      if (this.loadingType === 2){
        this.get()
      }
    })
  },
  methods:{
    openLoading(){
      this.loading = this.$loading({
        lock: true,
        text: this.loadingText,
        spinner: 'el-icon-loading'
      });
    },
    closeLoading(){
      if (this.loading){
        this.loading.close()
      }
    },
    showLove(){
      this.text = "我喜欢薛迪心❤"
      this.color = "pink"
    },
    get(){
      if (this.form.url === ""){
        this.$message.warning("请输入内容后再获取")
        return
      }
      if (this.init){
        this.loadingText = "正在获取中..."
        this.openLoading()
      }else{
        this.loadingType = 2
        this.loadingText = "正在冷启动Api...请稍后..."
        this.openLoading()
      }
      let url = this.form.url.trim();
      let tempSign = md5(url.length.toString() + md5(url)).toString()
      let centos = ''
      for (let i = 10; i< 18; i ++){
        centos += tempSign.charCodeAt(i)
      }
      centos = centos.substr(2,centos.length - 2);
      let sign = tempSign.substr(3,15) + centos + tempSign.substr(-15,11)
      this.form.result = ""
      this.images = []
      request({
        url:"/media",
        method: "post",
        data:{
          'url' :url,
          's' : sign
        }
      }).then(res => {
        this.$message.success(res.message)
        let data = res.data.data;
        let key = md5(sign).toString();
        let result = this.aesDecode(data,key,'tp://m.itwmw.com')
        result = JSON.parse(result)

        this.type = parseInt(res.data.type)
        this.images = result.images
        this.music = result.music
        this.video = result.video
      }).catch(e => {
        this.$message.warning(e.message)
      }).finally(()=>{
        this.closeLoading()
        this.loadingType = 0
      })
    },
    aesDecode(str,key,iv){
      key = CryptoJS.enc.Utf8.parse(key)
      iv = CryptoJS.enc.Utf8.parse(iv)
      let decrypted = CryptoJS.AES.decrypt(str,key,{
        iv: iv,
        padding: CryptoJS.pad.Pkcs7
      })
      return decrypted.toString(CryptoJS.enc.Utf8);
    },
    open(url){
      window.open(url,'_blank')
    },
    copyUrl(){
      if (!this.form.result){
        this.$message.warning("链接为空，无需复制")
        return
      }
      copy(this.form.result)
      this.$message.success("复制成功")
    },
    downloadMedia(){
      if (!this.form.result){
        this.$message.warning("链接为空，无法打开")
        return
      }
      download(this.form.result)
    },
    clear(){
      this.form.url = ""
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
